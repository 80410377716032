<template>
  <v-container fill-height justify-center>
    <v-layout style="width: 100%" row wrap>
      <v-flex xs12>
        <div
          class="display-1 text-center"
          v-html="$t('app.yourRoom', { number: authResult.room })"
        ></div>
      </v-flex>
      <v-flex xs12>
        <v-layout justify-center>
          <v-card color="transparent" flat style="max-width: 220px">
            <form name="param" method="GET">
              <!-- <v-btn
                :href="deepLink"
                type="submit"
                color="primary"
                fab
                style="width: 200px; height: 200px"
                data-v-step="key_0"
              >
                <v-layout align-center justify-center>
                  <v-icon size="160px">mdi-lock-open</v-icon>
                </v-layout>
              </v-btn> -->
              <v-btn
                color="primary"
                fab
                style="width: 200px; height: 200px"
                data-v-step="key_0"
                @click="$store.dispatch('onekey/openDoor')"
              >
                <v-layout align-center justify-center>
                  <v-icon size="160px">mdi-lock-open</v-icon>
                </v-layout>
              </v-btn>
            </form>
          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import MobileDetect from "mobile-detect";

export default {
  data: () => ({
    isIos: false,
    demoMesserschmitt: {
      mainAndroidLink:
        "intent:#Intent;action=com.messerschmitt.launchfrombrowser;package=com.messerschmitt.demoatvision;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;",
      parm1: "S.param1=",
      parm2: "S.param2=",
      endP: "end;",
      token: "test_device_identifier",
      key:
        "eyJ2YWxpZCI6MSwicmVhc29uIjotMSwic2MiOjg1LCJybSI6W3sibCI6IjEiLCJwIjoxfV0sInNhIjpbMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1LDI1NSwyNTUsMjU1XSwiZHQiOlsxNDAsMiwyNTMsMSwyNTQsMTk1LDk4LDQyLDYwLDE1NCwyMzcsODMsMTI0LDczLDEwOCwxMzksNDEsMjM2LDE4NCwxMzEsODIsMzIsMTYsMTM1LDE1MCw5NSw4LDI2LDc0LDIxMSw5LDIzMSwxMzEsMTc3LDEyNCwxMjIsMTM1LDIyNSw3NCwxOTldLCJkYSI6IjIwMjAtMDQtMzBUMTg6MDArMDA6MDAiLCJnIyI6IiJ9",
    },
  }),
  mounted() {
    const md = new MobileDetect(window.navigator.userAgent);
    this.isIos = md.os() === "iOS";
  },
  computed: {
    deepLink() {
      const { mode } = this.$store.state.session;
      const {
        token,
        key,
        mainAndroidLink,
        parm1,
        parm2,
        endP,
      } = this.demoMesserschmitt;
      if (mode === "demo") {
        if (this.isIos) {
          return `com.messerschmitt.demoatvision://launchfrombrowser?token=${token}&key=${key}&origin_url=${btoa(
            window.location.href
          )}`;
        } else {
          return `${mainAndroidLink}${parm1}${token};${parm2}${key};${endP}`;
        }
      } else {
        const { onekeySession = { link: "" } } = this.$store.state.session;
        return link;
      }
    },
    ...mapState({
      authResult: ({ session }) => session.authResult,
    }),
  },
};
</script>
